import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

export interface IAuthorCardProps {
  name: string;
  username: string;
  imageId: string;
  postsNum: number;
  clapCount: number;
}

const Wrapper = styled.div`
  margin: 1rem 1.5rem;
  display: flex;
  align-items: center;
  img {
    border-radius: 50%;
    margin: 0 0.5rem 0 0;
  }
  div {
    max-width: 225px;
  }
  a {
    text-decoration: none;
  }
  h3 {
    font-size: 1rem;
    margin: 0.25rem 0 0.25rem;
  }
  p {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 300;
  }
  :nth-of-type(1) {
    img {
      border: 3px solid #d6af36;
    }
  }
  :nth-of-type(2) {
    img {
      border: 3px solid #d7d7d7;
    }
  }
  :nth-of-type(3) {
    img {
      border: 3px solid #a77044;
    }
  }
`;

export const AuthorCard: React.SFC<IAuthorCardProps> = props => {
  const authorImage = `https://cdn-images-1.medium.com/fit/c/75/75/${props.imageId}`;
  const authorUrl = `https://medium.com/@${props.username}`;
  return (
    <Wrapper vocab="http://schema.org/" typeof="contributor">
      <LazyLoad height={75} width={75}>
        <a href={authorUrl} target="_blank" rel="noopener noreferrer">
          <img
            property="image"
            src={authorImage}
            alt={`Foto de ${props.name}`}
          />
        </a>
      </LazyLoad>
      <div>
        <a href={authorUrl} target="_blank" rel="noopener noreferrer">
          <h3 property="name">{props.name}</h3>
        </a>
        <p>
          {props.postsNum} post
          {props.postsNum > 1 ? 's' : null} com {props.clapCount} palmas
        </p>
      </div>
    </Wrapper>
  );
};

AuthorCard.displayName = 'AuthorCard';

export default AuthorCard;
